<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i class="fal fa-calendar-alt" style="margin:0 10px 0 0;font-size: 20px"></i>
            <span @click="main()">{{ $t("empRestDay.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name==='disableRestDayEmployee.create'">
            <span v-show="true">/</span>
            <span>{{ $t("empRestDay.create") }}</span>
          </v-breadcrumbs-item>

          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name==='disableRestDayEmployee.edit'">
            <span v-show="true">/</span>
            <span>Edit Holiday</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>

      </div>
    </div>

    <div class="page-content">
      <div class="content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  methods: {
    main() {
      this.$router.push({
        name: "disableRestDayEmployee.index"
      }).catch(() => {

      })
    },

  }
}
</script>

<style scoped lang="scss">

</style>
