import { render, staticRenderFns } from "./disableRestDayEmployee.vue?vue&type=template&id=496e016c&scoped=true&"
import script from "./disableRestDayEmployee.vue?vue&type=script&lang=js&"
export * from "./disableRestDayEmployee.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496e016c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem})
